<template>
  <van-uploader
    v-model="fileListInternal"
    :after-read="onAfterRead"
    name="file"
    multiple
    :max-count="maxCount"
  >
  </van-uploader>
</template>

<script>
import { Uploader } from "vant";
import fsClient from "common/api/fs-client";

export default {
  components: {
    [Uploader.name]: Uploader,
  },
  name: "Uploader",
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    maxCount: {
      type: Number,
      default: 5,
    },
  },
  model: {
    prop: "fileList",
    event: "update",
  },
  watch: {
    fileList(newVal) {
      this.fileListInternal = newVal;
    },
    fileListInternal(newVal) {
      this.$emit("update", newVal);
    },
  },
  data() {
    return {
      fileListInternal: [],
    };
  },
  mounted() {
    this.fileListInternal = this.fileList;
  },
  methods: {
    async onAfterRead(fArgs) {
      try {
        let files = fArgs instanceof Array ? fArgs : [fArgs];
        for (let file of files) {
          file.status = "uploading";
          file.message = "上传中...";
          let res = await fsClient.uploadFile(file.file);
          file.status = "done";
          file.url = res.url;
        }
      } catch (e) {
        console.error("上传文件失败", e);
        fArgs.status = "failed";
        fArgs.message = "上传失败...";
      }
    },
  },
};
</script>
