<template>
    <van-popup v-model="show" :title="$t('选择图片')" position="bottom" round>
        <div class="popup-box">
            <div class="van-picker__toolbar btns">
                <button @click="show = false" type="button" class="van-picker__cancel">
                    取消</button><button @click="onConfirm" type="button" class="van-picker__confirm">
                    确认
                </button>
            </div>
            <div class="list-box">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoadItems">
                    <van-grid square class="imglib-content">
                        <van-grid-item use-slot :key="item.id" v-for="item in items">
                            <div class="item-box">
                                <van-image fit="cover" :src="item.watermarkImageUrl" :lazy-load="false" />
                                <div @click="onImgLibItemSelect(item)"
                                    class="van-radio__icon van-radio__icon--round img-seled"
                                    :class="{ 'van-radio__icon--checked': item.selected }">
                                    <i class="van-icon van-icon-success" />
                                </div>
                            </div>
                        </van-grid-item>
                    </van-grid>
                </van-list>
            </div>
        </div>
    </van-popup>
</template>

<script>
import { Popup, Picker, Grid, GridItem, Image, List } from "vant";

export default {
    components: {
        [Popup.name]: Popup,
        [Picker.name]: Picker,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [Image.name]: Image,
        [List.name]: List,
    },
    name: "ImageLibPicker",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        maxCount: {
            type: Number,
            default: 5,
        },
    },
    model: {
        prop: "value",
        event: "visible",
    },
    watch: {
        show(newVal, oldVal) {
            if (newVal != oldVal && newVal) {
                for (let item of this.items) item.selected = false;
            }

            if (!newVal) {
                this.$emit("visible", false);
            }
        },
        value(newVal, oldVal) {
            this.show = this.value;
        },
    },
    data() {
        return {
            show: false,
            loading: false,
            finished: false,
            items: [],
            currentItemIndex: 0,
        };
    },
    mounted() {
        this.onLoadData();
    },
    methods: {
        async onLoadData() {
            this.loading = true;
            let res = await this.$userApi.imageLib.getImages();
            if (res.status == 200) {
                this.allItems = res.data.items;
                for (let item of this.allItems) {
                    item.selected = false;
                    item.url = item.watermarkImageUrl;
                    item.isImage = true;
                }
                console.log("图库图片数量：", this.allItems.length);
            }
            this.loading = false;
        },
        onLoadItems() {
            this.loading = true;
            //这里的页大小，如果不足一屏不触发滚动条，它不会自动加载下一页
            let pageSize = 35;
            for (let i = this.currentItemIndex, j = 0; i < this.allItems.length && j < pageSize; ++i, ++j) {
                this.items.push(this.allItems[i]);
            }
            this.currentItemIndex = this.items.length - 1;
            this.loading = false;
            if (this.items.length >= this.allItems.length)
                this.finished = true;
        },
        onImgLibItemSelect(item) {
            item.selected = !item.selected;
        },
        onConfirm() {
            let selItems = this.items.filter((x) => x.selected);
            if (selItems.length > this.maxCount) {
                this.$toast.fail(this.$t("您还能添加 {0} 张图片", [this.maxCount]));
                return;
            }

            this.$emit("confirm", {
                items: selItems,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.popup-box {
    height: 80vh;
    display: flex;
    flex-direction: column;

    .btns {}

    .list-box {
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
    }
}

.imglib-content {
    padding: 0.5rem 0 0rem 0;
}

/deep/ .van-grid-item__content {
    padding: 0;

    .item-box {
        position: relative;
        width: 100%;
        height: 100%;

        .van-image {
            width: 100%;
            height: 100%;
        }

        .img-seled {
            position: absolute;
            right: 0.2rem;
            top: 0.2rem;
        }
    }
}
</style>